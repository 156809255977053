import React, {Component} from 'react';
import ReleaseItem  from './ReleaseItem';



class PreviewItem extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedRelease:undefined
        }
    }

    componentDidMount(){
        fetch("/api/releases/preview",{
        headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Expose-Headers': 'Referer'
           }})
        .then(response => {
            if(window.location.href === response.headers.get("Referer")){
              return response.json()
            }else{
              throw new Error('referer and location mismatch');
            }
            
          })
        .then(data => {
            this.setState({selectedRelease: data});
        });
    }

    render() {
        if(!this.state.selectedRelease){return null}
        return (
            <div className="content">
                <ReleaseItem dataUrl={"/posts/preview"} preview={true} release={this.state.selectedRelease}></ReleaseItem>
            </div>
        );
    }
}


export default PreviewItem