import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {Typography,Button,Grid,Icon,List, ListItemText,Box} from '@material-ui/core';
import AsyncStorage from '@react-native-community/async-storage';
import {SearchContext} from '../contexts/SearchContext';
import './WelcomeTutorial.css';

class WelcomeTutorialModal extends Component {
    constructor(props) {
    super(props)

    this.dialogClose = this.dialogClose.bind(this);
}

componentDidMount(){
    AsyncStorage.getItem('VISITED_PAGE').then((value) => {
        if(value !== null) {
              this.context.state.setWelcomeModalVisible(false);
        }
    });
}

dialogClose(event){
    this.context.state.setWelcomeModalVisible(false);
    try {
        AsyncStorage.setItem('VISITED_PAGE', true)
    } catch (e) {
        console.log("Error storing visited value in localStorage")
    }
}

render(){
    return (
        <Dialog fullWidth={true} maxWidth="lg" onClose={this.dialogClose} open={this.context.state.welcomeModalVisible} aria-labelledby="customized-dialog-title" >
        <DialogContent className="popup-welcome" dividers>
          <Typography className="h1">
            Welcome
          </Typography>
          <Typography className="h2" gutterBottom>
            Before you proceed, please read the information below.
          </Typography>
          <Typography gutterBottom>
            The HelloID roadmap contains new features, the ability to vote on these features and the release of these features on the last Sunday of every month.
          </Typography>
          <Typography gutterBottom>
            The HelloID roadmap exists out of 5 destinations:
          </Typography>
          <Grid container direction="row" className="popup-dest ">
            <Grid item xs={12} md={3}>
              <Typography gutterBottom >
                <Icon>feedback</Icon>Under consideration
              </Typography>
              <List>
                <ListItemText variant="p">Interesting Feature</ListItemText>
                <ListItemText variant="p">Not confirmed to be implemented</ListItemText>
                <ListItemText variant="p">Discussed for implementation so effort is estimated</ListItemText>
                <ListItemText variant="p">Assinged release version is a estimate and can change</ListItemText>
              </List>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography gutterBottom >
                <Icon>timelapse</Icon>Milestones
              </Typography>
              <List>
                <ListItemText variant="p">Topic which exists of multiple features</ListItemText>
                <ListItemText variant="p">Priority by order, no dates</ListItemText>
                <ListItemText variant="p">Subject to change in the future</ListItemText>
                <ListItemText variant="p">Long term</ListItemText>
              </List>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography gutterBottom>
                <Icon>schedule</Icon>Planned
              </Typography>
              <List>
                <ListItemText variant="p">Feature is planned and confirmed</ListItemText>
                <ListItemText variant="p">Feature has a release version</ListItemText>
                <ListItemText variant="p">Short term</ListItemText>
                <ListItemText variant="p">Could be a feature of a larger milestone</ListItemText>
              </List>
            </Grid>
            <Grid item xs={12} md={2}>
              <Typography gutterBottom>
                <Icon>open_in_browser</Icon>Preview
              </Typography>
              <List>
                <ListItemText variant="p">HelloID preview for a release is available two weeks before the release</ListItemText>
                <ListItemText variant="p">Feature is done and available in HelloID preview</ListItemText>
                <ListItemText variant="p">Feedback is really appreciated and will be updated directly if possible</ListItemText>
              </List>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography gutterBottom>
                <Icon>launch</Icon>Launch
              </Typography>
              <List>
                <ListItemText variant="p">Feature is available in production</ListItemText>
                <ListItemText variant="p">Shows all the features for the last 6 months ordered by release</ListItemText>
              </List>
            </Grid>
          </Grid>
         
          <Typography className="h2" gutterBottom>
            Feedback
          </Typography>
          <Typography gutterBottom>
            We welcome your feedback & thoughts. Do you have anything to share please click on the "Give Feedback" button.
          </Typography>
          <Button variant="contained" target="_blank" color="primary" href="https://feedback.helloid.com/"><Icon>feedback</Icon><Box className="feedBackTextButton" display={{xs:'none', md:'block'}} m={1}>Give Feedback</Box></Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.dialogClose} color="primary">
            I Understand
          </Button>
        </DialogActions>
      </Dialog>
    )
}

}
WelcomeTutorialModal.contextType = SearchContext

export default WelcomeTutorialModal