import React from 'react';
import AppMenuBar from './modules/views/AppMenuBar';
import AppTab from './modules/views/AppTab';
import {Route, BrowserRouter, Redirect} from 'react-router-dom';
import FeatureGrid  from './modules/views/FeatureGrid';
import ReleaseGrid  from './modules/views/ReleaseGrid';
import PreviewItem  from './modules/views/PreviewItem';
import VisionGrid from './modules/views/VisionGrid';
import WelcomeTutorialModal from './modules/views/WelcomeTutorialModal';
import VideoModal from './modules/views/VideoModal';
import theme from './modules/theme';
import { ThemeProvider } from '@material-ui/styles';
import { SearchProvider } from './modules/contexts/SearchContext';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
      <div >
        <SearchProvider>
          <WelcomeTutorialModal></WelcomeTutorialModal>
          <VideoModal></VideoModal>
          <header>
            <AppMenuBar></AppMenuBar>
            <AppTab></AppTab>
          </header>
              <Route exact path="/">
                <FeatureGrid embed={false} dataURL="/posts/planned"></FeatureGrid>
              </Route>
              <Route exact path="/milestones"> 
                <VisionGrid embed={false}></VisionGrid>
              </Route>
              <Route exact path="/planned">
                <FeatureGrid embed={false} dataURL="/posts/planned"></FeatureGrid>
              </Route>
              <Route exact path="/under-consideration">
                <FeatureGrid embed={false} dataURL="/posts/under-review"></FeatureGrid>
              </Route>
              <Route exact path="/preview">
                <PreviewItem embed={false}></PreviewItem>
              </Route>
              <Route exact path="/launched/:releaseName">
                <ReleaseGrid embed={false}></ReleaseGrid>
              </Route>
              <Route exact path="/launched">
                <Redirect to="/launched/latest"/>
              </Route>
          </SearchProvider>
      </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;