import { createMuiTheme } from '@material-ui/core/styles';


const rawTheme = createMuiTheme({
  palette: {
    main:'rgba(37, 124, 225, 0.61)',
    primary: {
      light: 'rgba(37, 124, 225, 0.61)',
      main: 'rgba(37, 124, 225, 1)',
      dark: 'rgba(37, 114, 225, 1)',
    },
    secondary: {
      light: '#fff5f8',
      main: '#ff3366',
      dark: '#e62958',
    },
    warning: {
      main: '#ffc071',
      dark: '#ffb25e',
    },
    error: {
      xLight: '#ffebee',
      main: '#f44336',
      dark: '#d32f2f',
    },
    success: {
      main: '#e8f5e9',
      dark: '#388e3c',
    },
  }
});

const theme = {
  ...rawTheme,
  palette: {
    ...rawTheme.palette,
    background: {
      ...rawTheme.palette.background,
      default: rawTheme.palette.common.white,
      placeholder: '#eeeeee',
    },
  }
};

export default theme;