import React, {Component, useEffect} from 'react';
import {SearchContext} from '../contexts/SearchContext';
import {Typography, Grid, Container, Paper, Button, List, ListItem, Link, Avatar, Box, Chip, Tabs, Tab, Divider} from '@material-ui/core';
import Moment from 'react-moment';
import ReactMarkdown from 'react-markdown'
import PlayCircleFilledRoundedIcon from '@material-ui/icons/PlayCircleFilledRounded';
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';



class ReleaseItem extends Component {

    constructor(props) {
        super(props)
    
        this.videoClick = this.videoClick.bind(this);
    }

    componentDidMount(){
        this.context.state.setDataURL(this.props.dataUrl)
    }

    videoClick(url){
        if(url !== ''){
            this.context.state.setVideoModalVisible(true, url);
        }
    }

    render() {
        let majorFeatures=[]

        if(this.context.state.features){
            for(let feature of this.context.state.features){
                feature.inPreview = false;
                feature.needsGovernanceModule = false;
                if(feature.tags){
                    for(let tag of feature.tags){
                        if(tag.name === "MajorChange"){
                            majorFeatures.push(feature)
                        }
                        if(tag.name === "Preview"){
                            feature.inPreview = true;
                        }
                        if(tag.name === "Governance module"){
                            feature.needsGovernanceModule = true;
                        }
                    }
                }
            }
        }

        return (
                <Grid container item direction="row" md={12} xs={12} spacing={0} className="container" alignItems="flex-start">
                    <Grid container item direction="row" xs={12} md={3} spacing={0} className="container">
                        <Grid item xs={12} md={12}>
                            <Typography variant="h1">General</Typography>
                        </Grid>
                        <Grid item xs={12} md={12}>
                            <Paper variant="outlined">
                                    <Container>
                                        <Grid container direction="row" className="container" spacing={0}>
                                            <Grid item xs={12}>
                                                <Typography variant="h2">General information</Typography>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <Typography variant="body1"><b>Release date</b></Typography>
                                                <Moment format="Do MMMM">{this.props.release.releaseDate}</Moment>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                            <Typography variant="body1"><b>Webinar date</b></Typography>
                                                <Moment format="Do MMMM">{this.props.release.webinarDate}</Moment>
                                            </Grid>
                                            <Grid item xs={12} md={12}>
                                            <Typography variant="body1"><b>Major Changes</b></Typography>
                                                {majorFeatures &&
                                                <List dense={true} disablePadding={true} className="feature-list" >
                                                    {majorFeatures.map(feature=>
                                                    <ListItem key={"listItem" + feature.url}>
                                                        <Link  color="primary" className="listItemLink" href={feature.url} target="_blank">{feature.title}</Link>
                                                    </ListItem>
                                                    )}
                                                </List>
                                                }
                                            </Grid>
                                            {this.props.release.videoUrlWebinar !== '' &&
                                            <Grid item xs={12} md={12}>
                                                <List dense={true} disablePadding={true} className="feature-list" >
                                                    <ListItem key="webinar">
                                                        <Avatar  alt="Webinar video"><PlayCircleFilledRoundedIcon fontSize="large"  onClick={() => this.videoClick(this.props.release.videoUrlWebinar)}></PlayCircleFilledRoundedIcon></Avatar>
                                                        <Link  color="inherit" className="listItemLink" onClick={() => this.videoClick(this.props.release.videoUrlWebinar)}><b>Webinar video</b></Link>
                                                    </ListItem>
                                                </List>
                                            </Grid>
                                            }
                                    </Grid>
                                </Container>
                            </Paper>
                        </Grid>
                 
                    </Grid>
                    <Grid container item direction="row" xs={12} md={9} spacing={0} className="container">
                        <Grid item xs={12} md={12}>
                            <Typography variant="h1">Release {this.props.release.name}</Typography>
                        </Grid>
                        {Date.parse(this.props.release.webinarDate) > (new Date().valueOf() - 86400000) && this.props.release.webinarSubscribeUrl &&
                        <Grid item xs={12} md={12}>
                            <Paper variant="outlined">
                                <Grid container className="container" spacing={0}>
                                        <Grid item xs={12}>
                                            <Typography variant="h1">Subscribe for the webinar</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="body1">
                                                We offer new functionalities within HelloID every month. Are you curious? Would you like to stay informed? Then follow our monthly webinar! The new functionalities will be explained and there will be time for you to ask questions.
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <List className="webinarTimeList">
                                                <ListItem><Typography className="webinarTimeCountry" variant="body1">Germany</Typography><Typography className="webinarTime" variant="body1">@ 16.00 CET</Typography></ListItem>
                                                <ListItem><Typography className="webinarTimeCountry" variant="body1">The Netherlands</Typography><Typography className="webinarTime" variant="body1">@ 16.00 CET</Typography></ListItem>
                                                <ListItem><Typography className="webinarTimeCountry" variant="body1">France</Typography><Typography className="webinarTime" variant="body1">@ 16.00 CET</Typography></ListItem>
                                                <ListItem><Typography className="webinarTimeCountry" variant="body1">United Kingdom</Typography><Typography className="webinarTime" variant="body1"> @ 15.00 BST</Typography></ListItem>
                                                <ListItem><Typography className="webinarTimeCountry" variant="body1">New York</Typography><Typography className="webinarTime" variant="body1">@ 10.00 AM EDT</Typography></ListItem>
                                                <ListItem><Typography className="webinarTimeCountry" variant="body1">Seattle</Typography><Typography className="webinarTime" variant="body1"> @ 07.00 AM PDT</Typography></ListItem>
                                            </List>
                                        </Grid>
                                        
                                            <Grid item xs={12} md={12}>
                                                <Button variant="contained" color="primary" target="_blank" href={this.props.release.webinarSubscribeUrl}>Join to the webinar</Button>
                                            </Grid>
                                        
                                </Grid>
                            </Paper>
                        </Grid>
                        }
                        <Grid item xs={12} md={12}>
                            <Paper variant="outlined">
                                <ReleaseInformation majorFeatures={majorFeatures} context={this.context} preview={this.props.preview} release={this.props.release} />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
        )

    }
}

function ReleaseInformation(props){
    const [value, setValue] = React.useState("Features");
    const urlCH = "/api/changelog/release/" + props.release.name
    const [markdown, setMarkdown] = React.useState({});
    const [release, setRelease] = React.useState("latest");

    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    function fetchData(){
        fetch(urlCH,{
            method: "post",
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
          })
          .then(response => response.json())
          .then(data => {
            setMarkdown(data)
            setRelease(props.release.name)
          });
    }

    useEffect(() => {
        if (props.release.name !== release) {
            fetchData();
        }
        
    })
    if(props.context.state.loading){
        return (
            <Grid container direction="row"  spacing={0} className="content">
                <Grid item xs={12} >
                    <CircularProgress></CircularProgress>
                </Grid>
            </Grid>
        )
    }
    return(
        <Box>
        <Tabs value={value} onChange={handleChange}  indicatorColor="primary" textColor="primary">
            <Tab label="Features" value="Features"/>
            <Tab label="Notes" value="Notes"/>
        </Tabs>
        {value === "Features" ? 
            <DomainList context={props.context} features={props.context.state.features} preview={props.preview} release={props.release}  majorFeatures={props.majorFeatures}></DomainList>
            : 
            <ReleaseDetails markdown={markdown}></ReleaseDetails>
        }
        </Box>
    )
}


function DomainList(props){

    let featureList = undefined
    if(props.features){
        featureList = props.features.reduce((result, currentValue) => {
            (result[currentValue['board']['name']] = result[currentValue['board']['name']] || []).push(currentValue) 
            return result
        },{})
    }

    function videoClick(url){
        if(url !== ''){
            props.context.state.setVideoModalVisible(true, url);
        }
    }
    return (
            <Grid container direction="row" spacing={0} className="container">
            {featureList && featureList["Provisioning"] &&
                <Grid item xs={12} md={12} className="domainGridItem">
                    <Divider/>
                    <Typography className="domainTitle" variant="h2">
                        Provisioning
                    </Typography>
                    {props.release.videoUrlProv !== '' &&
                    <Link  color="inherit" onClick={() => videoClick(props.release.videoUrlProv)}>
                        <Paper className="domainVideoContainer" elevation={3} >
                            <div className="domainVideo">
                                <img width="150px" src="/thmb-Provisioning-HelloID.jpg" alt="Provisioning release video"></img>
                                <div>
                                    <p className="domainVideoTitle">Play release video</p>
                                    <p className="domainVideoSubTitle">This video shows all the new Provisioning features available in the {props.release.name} release</p>
                                </div>
                            </div>
                        </Paper>
                    </Link>
                    }
                   
                    <List dense={true} disablePadding={true} className="feature-list" >
                                {featureList["Provisioning"].map(feature=>
                                <Box key={"BoxItem" + feature.url}>
                                    <ListItem key={"listItem" + feature.url}>
                                            <Avatar  alt={feature.title} src={feature.imageURLs? feature.imageURLs[0]:"/feature_template.jpg"} />
                                            {feature.needsGovernanceModule ? <Tooltip title='This feature requires the HelloID Governance module license'><AccountBalanceIcon className="iconModuleGovernance" color="primary"></AccountBalanceIcon></Tooltip>: ""}
                                            <Link  color="inherit" className="listItemLink" href={feature.url} target="_blank">{feature.title}</Link>
                                            {props.preview ? feature.inPreview ? <Chip label="in preview"  />: "" : ""}
                                    </ListItem>
                                    
                                </Box>
                                )}
                    </List>
                   
                </Grid>
                }
                
                {featureList && featureList["Service Automation"] &&
                <Grid  item xs={12} md={12} className="domainGridItem">
                    <Divider/>
                    <Typography className="domainTitle" variant="h2">
                        Service Automation
                    </Typography>
                    {props.release.videoUrlSA !== '' &&
                    <Link  color="inherit" onClick={() => videoClick(props.release.videoUrlSA)}>
                        <Paper className="domainVideoContainer" elevation={3} >
                            <div className="domainVideo">
                                <img width="150px" src="/thmb-Service-Automation-HelloID.jpg" alt="Service Automation release video"></img>
                                <div>
                                    <p className="domainVideoTitle">Play release video</p>
                                    <p className="domainVideoSubTitle">This video shows all the new Service Automation features available in the {props.release.name} release</p>
                                </div>
                            </div>
                        </Paper>
                    </Link>
                    }
                     
                    <List dense={true} disablePadding={true} className="feature-list" >
                                {featureList["Service Automation"].map(feature=>
                                <Box key={"BoxItem" + feature.url}>
                                    <ListItem key={"listItem" + feature.url}>
                                            <Avatar  alt={feature.title} src={feature.imageURLs? feature.imageURLs[0]:"/feature_template.jpg"} />
                                            {feature.needsGovernanceModule ? <Tooltip title='This feature requires the HelloID Governance module license'><AccountBalanceIcon className="iconModuleGovernance" color="primary"></AccountBalanceIcon></Tooltip>: ""}
                                            <Link  color="inherit" className="listItemLink" href={feature.url} target="_blank">{feature.title}</Link>
                                            {props.preview ? feature.inPreview ? <Chip label="in preview" />: "" : ""}
                                    </ListItem>
                                
                                </Box>
                                )}
                    </List>
                
                </Grid>
                
                }
                {featureList && featureList["Access Management"] &&
                <Grid  item xs={12} md={12} className="domainGridItem">
                    <Divider/>
                    <Typography className="domainTitle" variant="h2">
                        Access Management
                    </Typography>
                    {props.release.videoUrlAM !== '' &&
                    <Link  color="inherit" onClick={() => videoClick(props.release.videoUrlAM)}>
                        <Paper className="domainVideoContainer" elevation={3} >
                            <div className="domainVideo">
                                <img width="150px" src="/thmb-Access-Management-HelloID.jpg" alt="Access Management release video"></img>
                                <div>
                                    <p className="domainVideoTitle">Play release video</p>
                                    <p className="domainVideoSubTitle">This video shows all the new Access Management features available in the {props.release.name} release</p>
                                </div>
                            </div>
                        </Paper>
                    </Link>
                    }
                    
                    <List dense={true} disablePadding={true} className="feature-list" >
                                {featureList["Access Management"].map(feature=>
                                <Box key={"BoxItem" + feature.url}>
                                    <ListItem key={"listItem" + feature.url}>
                                    <Avatar  alt={feature.title} src={feature.imageURLs? feature.imageURLs[0]:"/feature_template.jpg"} />
                                    {feature.needsGovernanceModule ? <Tooltip title='This feature requires the HelloID Governance module license'><AccountBalanceIcon className="iconModuleGovernance" color="primary"></AccountBalanceIcon></Tooltip>: ""}
                                            <Link  color="inherit" className="listItemLink" href={feature.url} target="_blank">{feature.title}</Link>
                                            {props.preview ? feature.inPreview ? <Chip label="in preview"  />: "" : ""}
                                    </ListItem>
                                </Box>
                                )}
                    </List>
                    
                </Grid>
                }

            </Grid>
            )
}

function ReleaseDetails(props){
    return(
        <Grid container direction="row" spacing={0} className="container">
            <Grid item xs={12} md={12} className="domainGridItem">
                <ReactMarkdown>{props.markdown.markdownDetails}</ReactMarkdown>
            </Grid>
        </Grid>
    )

}

ReleaseItem.contextType = SearchContext

export default ReleaseItem