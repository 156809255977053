import React, {Component} from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import {Button} from '@material-ui/core';
import {SearchContext} from '../contexts/SearchContext';

class VideoModal extends Component {
    constructor(props) {
    super(props)
    this.dialogClose = this.dialogClose.bind(this);
}

componentDidMount(){
}

dialogClose(event){
    this.context.state.setVideoModalVisible(false);
}

render(){
    return (
        <Dialog fullWidth={true} maxWidth="lg" onClose={this.dialogClose} open={this.context.state.videoModalVisible} aria-labelledby="customized-dialog-title" >
        <DialogContent className="popup-video" dividers>
            <div className="iframe-container">
                <iframe width="100%" title="webinarVideo" src={this.context.state.videoURL} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen>
                </iframe>
            </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.dialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )
}

}
VideoModal.contextType = SearchContext

export default VideoModal