import React, {Component} from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CircularProgress  from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button';
import { withRouter } from 'react-router-dom';
import ReleaseItem  from './ReleaseItem';
import './ReleaseGrid.css';
import {SearchContext} from '../contexts/SearchContext';
import Drawer from '@material-ui/core/Drawer';


class ReleaseGrid extends Component {
    constructor(props) {
        super(props)
        this.state = {
            releases:[],
            selectedRelease:undefined,
            drawerOpen: false,
            loading: true
        }

        this.handleChange = this.handleChange.bind(this);
        this.toggleDrawer = this.toggleDrawer.bind(this);
    }

    handleChange(e, value) {
        for(let release of this.state.releases){
            if(release.name === value){
                this.setState({selectedRelease: release});
            }
        }
        this.props.history.push(this.props.embed?'/embed/launched/'+ value:'/launched/' + value)
        this.context.state.setDataURL("/posts/released/" + value)
    }

    toggleDrawer(open) {
        if (open !== undefined){
            this.setState({drawerOpen:open})
        }
    }

    componentDidMount(){
        this.setState({loading:true})
        fetch("/api/releases",{
            headers : { 
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Access-Control-Expose-Headers': 'Referer'
               }})
        .then(response => {
            if(window.location.href === response.headers.get("Referer")){
              return response.json()
            }else{
              throw new Error('referer and location mismatch');
            }
            
          })
        .then(data => {
            this.setState({releases: data.releases})
            let release = undefined
            for(let r of this.state.releases){
                if(r.name === this.props.match.params.releaseName){
                    release = r
                }
            }
            if(!release){release = this.state.releases[0]}
            this.setState({selectedRelease: release})
            this.setState({loading: false})
            this.context.state.setDataURL("/posts/released/" + release.name)
        });
    }

    render() {
        if(this.state.loading){return (
            <Grid container direction="row"  spacing={0} className="content">
                <Grid item xs={12} >
                    <CircularProgress></CircularProgress>
                </Grid>
            </Grid>
        )}
        if(!this.state.selectedRelease){return null}
        return (
            <Grid container direction="row"  spacing={0} className="content">
                <Grid item className="SelectReleaseContainer" xs={12} >
                    <Button onClick={()=> {this.toggleDrawer(true)}}>
                        Select another release
                    </Button>
                </Grid>
                <ReleaseItem dataUrl={"/posts/released/" + this.state.selectedRelease.name} preview={false} release={this.state.selectedRelease}></ReleaseItem>
                <Drawer anchor={"right"} open={this.state.drawerOpen} onClose={()=>{this.toggleDrawer(false)}}>
                    <Typography variant="h6" gutterBottom noWrap>
                            Releases
                    </Typography>
                    <Tabs orientation="vertical" variant="scrollable" value={this.state.selectedRelease.name} onChange={(e,v)=>{this.handleChange(e,v);this.toggleDrawer(false);}} indicatorColor="primary">
                        {this.state.releases.map((release)=> 
                            <Tab key={release.name} className="release-header-tab" label={release.name} value={release.name}></Tab>
                        )}
                    
                    </Tabs>
                </Drawer>
                <Grid item className="container" md={2} xs={12}>


                </Grid>
            </Grid>
        );
    }
}

ReleaseGrid.contextType = SearchContext

export default withRouter(ReleaseGrid)