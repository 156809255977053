import React,  { createContext  } from 'react';
import AsyncStorage from '@react-native-community/async-storage';

const SearchContext = createContext({});

class SearchProvider extends React.Component {
  constructor(props) {
    super(props)
    let featureView = "list"
    this.state = {
      searchFilter:'',
      categoryFilter:["General","Access Management", "Service Automation", "Provisioning"],
      dataURL:'',
      featureView: featureView,
      features:[],
      welcomeModalVisible:true,
      videoModalVisible:false,
      videoURL:'',
      loading: false,
      setSearchFilter: (value)  => {
        this.setState({searchFilter:value})
        this.getFeatures(this.state.dataURL, value, this.state.categoryFilter)
      },
      setCategoryFilter: (value) => {
        this.setState({categoryFilter:value})
        this.getFeatures(this.state.dataURL, this.state.searchFilter, value)
      },
      switchSingleCategoryFilter: (value) => {
        let currentFilter = this.state.categoryFilter
        if (currentFilter.indexOf(value) > -1){
          currentFilter.splice(currentFilter.indexOf(value),1)
        }else{
          currentFilter.push(value)
        }
        this.state.setCategoryFilter(currentFilter)
      },
      setDataURL: (value) => {
        this.setState({loading:true})
        this.setState({dataURL:value})
        this.getFeatures(value, this.state.searchFilter, this.state.categoryFilter)
      },
      setFeatureView: (value) => {
        this.setState({featureView:value})
        try {
          AsyncStorage.setItem('FEATURE_VIEW', value)
        } catch (e) {
          console.log("Error storing feature view value in localStorage")
        }
      },
      checkCategoryInFilter: (value) => {
        return this.state.categoryFilter.includes(value)
      },
      setWelcomeModalVisible: (value) => {
        this.setState({welcomeModalVisible:value})
      },
      setVideoModalVisible: (value, url) => {
        this.setState({videoModalVisible:value})
        this.setState({videoURL:url})
      }
    }

    AsyncStorage.getItem('FEATURE_VIEW').then((value) => {
      if(value !== null) {
        this.state.setFeatureView(value)
      }
  });
  }

  getFeatures(dataURL, searchFilter, categoryFilter){
    let b = {search_filter: searchFilter,category_filter: categoryFilter}
    fetch("/api" + dataURL,{
      method: "post",
      body: JSON.stringify(b),
      headers : { 
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Access-Control-Expose-Headers': 'Referer'
       }
    })
    .then(response => {
      if(window.location.href === response.headers.get("Referer")){
        return response.json()
      }else{
        throw new Error('referer and location mismatch');
      }
      
    })
    .then(data => {
      this.setState({features: data.posts.sort(this.sortFeatures)})
      this.setState({loading:false})
    })
    .catch((error) => {
      if(error.message !== 'referer and location mismatch'){
        console.log(error)
      }

    });
  }

  sortFeatures(a,b) {
    if (a.eta === ''){
     return new Date("1 December 2099") - new Date("1 "+ b.eta)
    }
    if (b.eta === ''){
      return new Date("1 "+ a.eta) - new Date("1 December 2099") 
    }
    return new Date("1 "+ a.eta) - new Date("1 "+ b.eta) 
  }



  render() {
    return (<SearchContext.Provider value={{state: this.state}}>{this.props.children}</SearchContext.Provider>)
  }
};

export { SearchContext, SearchProvider };