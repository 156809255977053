import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import {Link} from 'react-router-dom';
import { withRouter } from 'react-router-dom';


class AppTab extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value: this.props.location.pathname === "/" ? "/planned": this.props.location.pathname.split('/').slice(0, 2).join('/'),
    }
  }

  render(){
    const handleChange = (event, newValue) => {
      this.setState({value: newValue});
    };

    return (
          <Tabs
              value={this.state.value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="on"
          >
              <Tab label="Under Consideration" icon={<Icon>feedback</Icon>}  value="/under-consideration" component={Link} to="/under-consideration"/>
              <Tab label="Milestones" icon={<Icon>timelapse</Icon>} value="/milestones" component={Link} to="/milestones"/>
              <Tab label="Planned" icon={<Icon>schedule</Icon>} value="/planned"  component={Link} to="/planned"/>
              <Tab label="Next Release" icon={<Icon>open_in_browser</Icon>} value="/preview"  component={Link} to="/preview"/>
              <Tab label="Launched" icon={<Icon>launch</Icon>} value="/launched"  component={Link} to="/launched"/>

      </Tabs>
    );
  }
    
}

export default withRouter(AppTab)
