import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import './VisionGrid.css';
import {SearchContext} from '../contexts/SearchContext';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import {CardContent, Tooltip } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress'
import ListItem from '@material-ui/core/ListItem'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import Avatar from '@material-ui/core/Avatar'
import TimelineDot from '@material-ui/lab/TimelineDot'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import Icon from '@material-ui/core/Icon'
import Badge from '@material-ui/core/Badge'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';



class VisionGrid extends Component {
        constructor(props) {
        super(props)
        this.state = { 
            visionList:[],
            loading: true,
            milestoneModalVisible:false,
            relatedRequests:[],
            selectedMilestone:"",
            milestoneLoading:false,
        }

        this.dialogClose = this.dialogClose.bind(this);
        this.dialogOpen = this.dialogOpen.bind(this);
    }

    
   
    componentDidMount(){
       this.setState({loading:true})
       const urlVision = "/api/vision"
        fetch(urlVision,{
            method: "post",
            headers : { 
              'Content-Type': 'application/json',
              'Accept': 'application/json'
             }
          })
          .then(response => response.json())
          .then(data => {
              this.setState({visionList:data})
              this.setState({loading: false})
          });

          
    }

     dialogClose (event) {
        this.setState({milestoneModalVisible:false})
    }

    dialogOpen (milestone){
        this.setState({milestoneLoading:true})
        this.setState({selectedMilestone: milestone})
        this.setState({milestoneModalVisible:true})

        const urlMilestone = "/api/posts/milestone/" + milestone.name
        fetch(urlMilestone,{
          method: "post",
          headers : { 
            'Content-Type': 'application/json',
            'Accept': 'application/json'
           }
        })
        .then(response => response.json())
        .then(data => {
            let relatedRequests = data.posts
            if(relatedRequests == undefined){
                relatedRequests= []
            }
             this.setState({relatedRequests:relatedRequests})
             this.setState({milestoneLoading:false})
        });
    }


    render() {
        
        if(this.state.loading){
            return (
                <Grid container direction="row"  spacing={0} className="content">
                    <Grid item xs={12} >
                        <CircularProgress></CircularProgress>
                    </Grid>
                </Grid>
            )
        }

        return (
            <Grid container direction="column" className="content">
       
                <Grid container item xs={12} md={12} direction="row" spacing={0}>
                {this.state.visionList.sort().map(list =>
                    
                    <Grid key={list.list_name} item xs={12} md={4} className="domainGridItem">
                        <Paper>
                            <Grid className="domainGridItem" item xs={12}>
                        <Typography variant="h2" component="h1">
                            {list.list_name}
                        </Typography>
                        <Divider></Divider>
                        <Timeline align="left">
                            {list.cards.map(card=>
                            <TimelineItem key={card.name}>
                         
                                <TimelineSeparator>
                                    <TimelineDot color="primary" variant={card.hasOwnProperty("labels")? card.labels.some(el => el.name === "In progress") ? "default" : "outlined": "outlined"} />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                <Card className="cardMilestoneItem">
                                    <CardContent>
                                    <Typography className="visionTitle" variant="body1">
                                            {card.name}
                                        </Typography>
                                        <Typography className="visionDescription" variant="body1">{card.desc}</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Grid container direction='row' justifyContent="space-between">
                                            <Grid item xs={12} md={6}>
                                                {card.hasOwnProperty("labels")? card.labels.some(el => el.name === "In progress") ? <Chip label="in progress" variant="outlined" /> : "" : ""}
                                                {card.hasOwnProperty("labels")? card.labels.some(el => el.name === "Governance Module") ? <Tooltip title='The features in this milestone require the HelloID Governance license'><Chip label="Governance module" variant="outlined"  icon={<AccountBalanceIcon className="chipGovernance" color="primary"></AccountBalanceIcon>}  /></Tooltip> : "" : ""}  
                                            </Grid>
                                            <Grid item xs={12} md={6} container justifyContent="flex-end">
                                                <Button className="buttonMilestoneDialog" color="primary" onClick={() => this.dialogOpen(card)}>
                                                    Learn More
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </CardActions>
                                </Card>
                                        
                                </TimelineContent>
                            </TimelineItem>
                            )}
                        </Timeline>
                        </Grid>
                        </Paper>
                    </Grid>
                        )}
                </Grid> 
                <Dialog fullWidth={true} maxWidth="lg" onClose={this.dialogClose} open={this.state.milestoneModalVisible} aria-labelledby="customized-dialog-title" >
                    <DialogContent className="popup-video" dividers>
                    <Grid container direction="row" >
                        <Grid item xs={12} md={12}>
                            <Typography variant="h1" className="milestoneTitle">
                                {this.state.selectedMilestone.name}
                            </Typography>
                            <Typography variant="body2" className="milestoneSummary" color="textSecondary">
                                {this.state.selectedMilestone.desc}
                            </Typography>

                            <Typography variant="h2" className="relatedRequestTitle">
                                Related requests
                            </Typography>
                            <Typography variant="body2" className="relatedRequestSummary" color="textSecondary">
                            The following feature requests pertain to the selected milestone, providing an overview of the issues to be addressed during implementation. Please note that this list is subject to change, and no conclusions are binding based on this preliminary information
                            </Typography>
                            <Divider></Divider>
                            {this.state.milestoneLoading? <CircularProgress></CircularProgress>:<FeatureList relatedRequests={this.state.relatedRequests}  ></FeatureList>}
                            </Grid>
                        </Grid>            
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.dialogClose} color="primary">
                            Close
                        </Button>
                    </DialogActions>        
                </Dialog>
            </Grid>
        );
    }
}

function FeatureList(props){
    if(props.relatedRequests){
        for(let feature of props.relatedRequests){
            feature.needsGovernanceModule = false;
            if(feature.tags){
                for(let tag of feature.tags){
                    if(tag.name === "Governance module"){
                        feature.needsGovernanceModule = true;
                    }
                }
            }
        }
    }
        return(
            <Grid container  justifyContent="space-between">
            {props.relatedRequests.sort(compareStates).map(feature=>
            <Grid key={"item" + feature.url} item xs={12}>
                <Card className="cardMilestoneFeatureItem">
                <CardContent>
                         <Grid container direction='row' justifyContent="space-between">
                            <Grid item xs={12} md={11} container justifyContent="flex-start" className='feature-list'>
                                    <div className="cardMilestoneFeatureItemBox">
                                        <Avatar className="milestoneFeatureHeader" alt={feature.title} src={feature.imageURLs? feature.imageURLs[0]:"/feature_template.jpg"} />
                                        {feature.needsGovernanceModule ? <Tooltip title='This feature requires the HelloID Governance module license'><AccountBalanceIcon className="iconModuleGovernance" color="primary"></AccountBalanceIcon></Tooltip>: ""}
                                    </div>
                                    <Typography variant="subtitle2" className="milestoneFeatureHeaderTitle"  gutterBottom component="h1">
                                        <Link color="inherit"  href={feature.url} target="_blank">{feature.title}</Link>
                                    </Typography>
                                    
                            </Grid>
                            <Grid item xs={12} md={1} container justifyContent="flex-end">
                                <Chip label={feature.status} variant="default" className={feature.status === "complete" ? "chipComplete": feature.status === "planned" || feature.status === "in progress" ? "chipPlanned":"chipOpen"} />
                            </Grid>
                        </Grid>

                        <Grid container direction='row' justifyContent="space-between">
                            <Grid item xs={12} md={3}>
                                <Typography paragraph variant="body2" color="textPrimary" className="card-info-eta" component="p" noWrap>
                                        {feature.eta === '' ? 'No estimate' : feature.status === 'complete'? <Link   href={"launched/" + feature.eta} target="_blank">{"Release: " + feature.eta}</Link> : "Estimate: " + feature.eta}
                                </Typography>
                            </Grid>
                            <Grid item xs={false} md={8}>

                            </Grid>
                            <Grid item xs={12} md={1} container justifyContent="flex-end">
                                <Badge color="primary" badgeContent={feature.score} className="card-info-score"><Icon>person</Icon></Badge>
                            </Grid>   
                        </Grid>
                        <Typography variant="body2" className="card-details" color="textSecondary" component="p">
                        {feature.details}
                        </Typography>
                        </CardContent>
                </Card>
            </Grid>
            )}
            {!props.relatedRequests.length >0?<ListItem><Typography>At present, there are no assigned feature requests associated with this milestone.</Typography></ListItem>:""}            
        </Grid>  
        )
}

function compareStates(a, b){
    let scoreA = (a.status === 'planned' || a.status === 'in progress')? 3 : a.status === 'complete'? 1 : 2
    let scoreB = (b.status === 'planned' || b.status === 'in progress')? 3 : b.status === 'complete'? 1 : 2

    let dateScoreA = new Date("1 "+ a.eta).getTime();
    let dateScoreB = new Date("1 "+ b.eta).getTime();
    if(scoreA < scoreB){
        return 1;
    }else if(scoreA > scoreB){
        return -1;
    }else if(dateScoreA < dateScoreB){
        return 1;
    }else if (dateScoreA > dateScoreB){
        return -1
    }else{
        return 0
    }
}



VisionGrid.contextType = SearchContext

export default VisionGrid