import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import FeatureItem from './FeatureItem';
import './FeatureGrid.css';
import {SearchContext} from '../contexts/SearchContext';
import ViewListIcon from '@material-ui/icons/ViewList';
import ListIcon from '@material-ui/icons/List';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { List, ListItem, Typography, Divider, Link, Paper } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';



class FeatureGrid extends Component {
        constructor(props) {
        super(props)

        this.handleViewChange = this.handleViewChange.bind(this);
    }
   
    componentDidMount(){
      this.context.state.setDataURL(this.props.dataURL)
    }

    handleViewChange(event, value){
        this.context.state.setFeatureView(value)
    }

    render() {
        if(this.context.state.loading){
            return (
                <Grid container direction="row"  spacing={0} className="content">
                    <Grid item xs={12} >
                        <CircularProgress></CircularProgress>
                    </Grid>
                </Grid>
            )
        }

        if(this.context.state.features){
            for(let feature of this.context.state.features){
                feature.needsGovernanceModule = false;
                if(feature.tags){
                    for(let tag of feature.tags){
                        if(tag.name === "Governance module"){
                            feature.needsGovernanceModule = true;
                        }
                    }
                }
            }
        }

        let gridBody
        if(this.context.state.featureView === 'list'){
            gridBody = <FeatureGridList  features={this.context.state.features}></FeatureGridList>
        }else{
            gridBody = <FeatureGridItems features={this.context.state.features}></FeatureGridItems>
        }

        return (
            <Grid container direction="column" className="content">
                <Grid container direction="row-reverse" className="gridButtonGroup container">
                    <ToggleButtonGroup value={this.context.state.featureView} exclusive onChange={this.handleViewChange}>
                        <ToggleButton value="list" aria-label="left aligned">
                            <ListIcon></ListIcon>
                        </ToggleButton>
                        <ToggleButton value="grid" aria-label="left aligned">
                            <ViewListIcon></ViewListIcon>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
                {gridBody}
            </Grid>
        );
    }
}

function FeatureGridItems(props){
    let key = 'eta'
    let featureList = props.features.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue) 
        return result
    },{})
    return (<Grid container className="container" direction="column" spacing={0} >
                {Object.keys(featureList).map(key =>
                <Grid item className="releaseGridItem">
                    <Typography variant="h1" className="releaseTitle">
                        {key.length>0? key:"No estimate"}
                    </Typography>
                    <Divider class="gridDivider"></Divider >
                    <Grid container  direction="row" spacing={2} >
                        {featureList[key].map(feature=>
                            <FeatureItem feature={feature}></FeatureItem>
                        )}
                    </Grid>
                </Grid>
                )}
            </Grid>)
}

function FeatureGridList(props){
    let key = 'eta'
    let featureList = props.features.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue) 
        return result
    },{})

    return (<Grid container className="container" direction="row">
                {Object.keys(featureList).map(key =>
                <Grid key={key} item container xs={12} md={12} >
                    <Grid item xs={12} md={12} className="releaseGridItem">
                        <Typography variant="h1" className="releaseTitle">
                            {key.length>0? key:"No estimate"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <DomainGridList features={featureList[key]}></DomainGridList>
                    </Grid>
                </Grid>
                )}
            </Grid>)
}

function DomainGridList(props){
    let featureList = props.features.reduce((result, currentValue) => {
        (result[currentValue['board']['name']] = result[currentValue['board']['name']] || []).push(currentValue) 
        return result
    },{})

    return (
            <Paper variant="outlined" className="domainListPaperContainer">
            <Grid container direction="row" spacing={0}>
                {Object.keys(featureList).sort().map(key =>
                <Grid key={key} item xs={12} md={4} className="domainGridItem">
                    <Typography variant="h2">
                        {key.length>0? key:"General"}
                    </Typography>
                    <Divider></Divider>
                    <List dense={true} disablePadding={true} className="feature-list" >
                                {featureList[key].map(feature=>
                                <ListItem key={"listItem" + feature.url}>
                                     <Avatar  alt={feature.title} src={feature.imageURLs? feature.imageURLs[0]:"/feature_template.jpg"} />
                                     {feature.needsGovernanceModule ? <Tooltip title='This feature requires the HelloID Governance module license'><AccountBalanceIcon className="iconModuleGovernance" color="primary"></AccountBalanceIcon></Tooltip>: ""}
                                     <Link  color="inherit" className="listItemLink" href={feature.url} target="_blank">{feature.title}</Link>
                                 </ListItem>
                                )}
                    </List>
                </Grid>
                )}
            </Grid>
            </Paper>
            )
}

FeatureGrid.contextType = SearchContext

export default FeatureGrid