import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import './FeatureGrid.css';
import { Icon, Badge } from '@material-ui/core';

class FeatureItem extends Component {

    render() {
        return (
            <Grid item xs={12} lg={3} md={3} sm={6}>
                        <Card>
                    <CardActionArea target="_blank" href={this.props.feature.url} >
                        <CardMedia
                        className="media"
                        image={this.props.feature.imageURLs? this.props.feature.imageURLs[0]: "/feature_template.jpg"}
                        title={this.props.feature.title}
                        />
                        <CardContent>
                         <div className="card-info">
                        <Typography variant="subtitle2" gutterBottom component="h1">
                            {this.props.feature.title}
                        </Typography>
                        
                        </div>
                        <div className="card-info">
                        <Icon>schedule</Icon>
                        <Typography paragraph variant="body2" color="textPrimary" className="card-info-eta" component="p" noWrap>
                                {this.props.feature.eta === '' ? "No estimate": "Estimate: " + this.props.feature.eta}
                        </Typography>
                            
                            <Badge color="primary" badgeContent={this.props.feature.score} className="card-info-score"><Icon>person</Icon></Badge>
                        </div>
                        <Typography variant="body2" className="card-details" color="textSecondary" component="p">
                        {this.props.feature.details}
                        </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button target="_blank" href={this.props.feature.url} size="small" color="primary">
                        Learn More
                        </Button>
                        <Chip className="card-info-category-chip" size="small" color="default" label={this.props.feature.board.name}></Chip>
                    </CardActions>
                    </Card>
                </Grid>
        )
    }
}

export default FeatureItem