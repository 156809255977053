import React from 'react';
import {Route, BrowserRouter} from 'react-router-dom';
import FeatureGrid  from './modules/views/FeatureGrid';
import ReleaseGrid  from './modules/views/ReleaseGrid';
import PreviewItem  from './modules/views/PreviewItem';
import theme from './modules/theme';
import { ThemeProvider } from '@material-ui/styles';
import { SearchProvider } from './modules/contexts/SearchContext';

function Embed() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
      <div className="embed">
        <SearchProvider>
              <Route exact path="/embed/planned">
                <FeatureGrid embed={true} dataURL="/posts/planned"></FeatureGrid>
              </Route>
              <Route exact path="/embed/under-consideration">
                <FeatureGrid embed={true} dataURL="/posts/under-review"></FeatureGrid>
              </Route>
              <Route exact path="/embed/preview">
                <PreviewItem embed={true}></PreviewItem>
              </Route>
              <Route exact path="/embed/launched/:releaseName">
                <ReleaseGrid embed={true}></ReleaseGrid>
              </Route>
          </SearchProvider>
      </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default Embed;