import React, { Fragment } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import InputBase from '@material-ui/core/InputBase';
import {SearchContext} from '../contexts/SearchContext';
import {DebounceInput} from 'react-debounce-input';
import { Button, Box, Icon,IconButton, Menu, MenuItem } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';



const useStyles = makeStyles(theme => ({
  container:{
    display:'flex',
  },
  logo: {
    padding: theme.spacing(2),
    width: '36px',
    height: '36px',
  },
  title: {
    display: 'none',
    marginTop:'0',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      marginTop:'16px',
    },
  },
  search: {
    position: 'relative',
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing(7),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    marginTop: theme.spacing(2),
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  feedback: {
    margin:0,
    paddingTop:"15px",
    marginLeft:"10px"
  },
  feedbackText: {
    margin:0,
    marginLeft:"5px",
  },
  help: {
    margin:0,
    flexGrow:"1",
    paddingTop:"3px"
  },
}));

export default function AppMenuBar() {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Toolbar>
            <img className={classes.logo} src="/helloid-icon.png" alt="logo"/>
            <Typography variant="h1" noWrap>
              HelloID Roadmap
            </Typography>
            <SearchContext.Consumer>
            {context => 
            <Fragment>
              <div className={classes.help}>
                <IconButton color="primary" onClick={(e) => context.state.setWelcomeModalVisible(true)}><HelpIcon></HelpIcon></IconButton>
              </div>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                  
                    <DebounceInput
                    minLength={3}
                    debounceTimeout={500}
                    element={InputBase}
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                    value={context.state.searchFilter}
                    onChange={(e) => context.state.setSearchFilter(e.target.value)}
                    /> 
              </div>
              <div className={classes.feedback}>
                <Button variant="contained" color="default" onClick={handleClick} aria-controls="customized-menu" aria-haspopup="true">
                    <Icon>filter_list</Icon>
                </Button>
                <Menu id="customized-menu" elevation={0} anchorEl={anchorEl}  open={Boolean(anchorEl)} onClose={handleClose}>
                    <MenuItem onClick={(e) => context.state.switchSingleCategoryFilter("General")}><Checkbox checked={context.state.checkCategoryInFilter("General")} value="General"></Checkbox>General</MenuItem> 
                    <MenuItem onClick={(e) => context.state.switchSingleCategoryFilter("Access Management")}><Checkbox checked={context.state.checkCategoryInFilter("Access Management")}  value="Access Management"></Checkbox>Access Management</MenuItem>
                    <MenuItem onClick={(e) => context.state.switchSingleCategoryFilter("Service Automation")}><Checkbox checked={context.state.checkCategoryInFilter("Service Automation")} value="Service Automation"></Checkbox>Service Automation</MenuItem>
                    <MenuItem onClick={(e) => context.state.switchSingleCategoryFilter("Provisioning")}><Checkbox checked={context.state.checkCategoryInFilter("Provisioning")} value="Provisioning"></Checkbox>Provisioning</MenuItem>
                </Menu>
              </div>
              </Fragment>
              }
            </SearchContext.Consumer>
            <div className={classes.feedback}>
              <Button variant="contained" target="_blank" color="primary" href="https://feedback.helloid.com/"><Icon>feedback</Icon><Box className={classes.feedbackText} display={{xs:'none', md:'block'}} m={1}>Give Feedback</Box></Button>
            </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}

